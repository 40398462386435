<div class="selectControl">
    <mat-form-field [color]="color" [appearance]="appearance">
        <mat-label>{{label}}</mat-label>
        <mat-select [formControl]="selectListCtrl" [placeholder]="placeholder" [multiple]="isMultiple" (selectionChange)="getSelectedValue($event)" #selectControl>
          <mat-option>
            <ngx-mat-select-search 
                [showToggleAllCheckbox]="showSelectAll"
                [toggleAllCheckboxIndeterminate]="selectIndeterminate"
                [toggleAllCheckboxChecked]="selectChecked"
                (toggleAll)="toggleSelectAll($event)"
                [placeholderLabel]="searchPlaceholder"
                [noEntriesFoundLabel]="noDataSearch"
                [formControl]="selectListFilterCtrl"
                (toggleAll)="getSelectAllValue($event)">
            </ngx-mat-select-search>
          </mat-option>
          @for(item of filteredSelectList | async; track item){
            <mat-option [value]="item">
              @for(name of displayName; track name; let last = $last){
                @if(last){{{item[name]}}}
                @else{{{item[name]}} - }
              }
            </mat-option>
          }
        </mat-select>
        @if(required && selectListCtrl.touched && selectListCtrl.invalid){<mat-error>{{requiredMessage}}</mat-error>}
    </mat-form-field>
    @if(loaderStatus){<mat-spinner [diameter]="29" [strokeWidth]="3"></mat-spinner>}
</div>
import { Component, OnInit, OnChanges, AfterViewInit, Input, Output, EventEmitter, SimpleChanges, ViewChild, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule, MatSelect, MatSelectChange } from '@angular/material/select';
import { ReactiveFormsModule, FormControl, Validators } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

export interface IreceiveSelect{
  name: string
  value: any
}

@Component({
  selector: 'app-select',
  standalone: true,
  imports: [CommonModule, MatSelectModule, ReactiveFormsModule, MatProgressSpinnerModule, NgxMatSelectSearchModule],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss'
})
export class SelectComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy{
  @Input() option: any[] = [];
  @Input() selectedList: any = null;
  @Input() displayName: string[] = [];
  @Input() required: boolean = false;
  @Input() requiredMessage: string = "";
  @Input() isMultiple: boolean = false;
  @Input() showSelectAll: boolean = false;
  @Input() selectIndeterminate: boolean = false;
  @Input() selectChecked: boolean = false;
  @Input() loaderStatus: boolean = false;
  @Input() controlName: string = "";
  @Input() id: string = "";
  @Input() searchProperty: string = "";
  @Input() color: 'primary' | 'accent' = "primary";
  @Input() appearance: 'fill' | 'outline' = "outline";
  @Input() label: string = "";
  @Input() placeholder: string = "Select Option";
  @Input() searchPlaceholder: string = "Search";
  @Input() noDataSearch: string = "Search not found";
  @Output() sendSelectValue = new EventEmitter<any>();
  selectListCtrl: FormControl<any> = new FormControl<any>(null);
  selectListFilterCtrl: FormControl<any> = new FormControl<any>('');
  filteredSelectList: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  filteredSelectListCache: any[] = [];
  @Input() isIndeterminate = true;
  @Input() isChecked = true;
  @ViewChild('selectControl', { static: true }) selectControl: MatSelect;
  onDestroy = new Subject<void>();

  ngOnInit(): void {
    this.selectListFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterSelectList();
        this.setToggleAllCheckboxState();
      });

    this.selectListCtrl.valueChanges
    .pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.setToggleAllCheckboxState();
    });
  }

  ngOnChanges(ch: SimpleChanges): void {
    if(ch['option']){
      this.option && this.option.length > 0 ? this.filteredSelectList.next(this.option.slice()) : this.filteredSelectList.next([]);
    }
    if(ch['selectedList']){
      this.selectedList ? this.selectListCtrl.setValue(this.selectedList) : this.selectListCtrl.setValue(null);
    }
    if(ch['required']){
      this.required ? this.selectListCtrl.setValidators([Validators.required]) : this.selectListCtrl.clearValidators();
      this.selectListCtrl.updateValueAndValidity();
    }
  }

  getSelectedValue(event: MatSelectChange){
    this.sendSelectValue.emit({name: this.controlName, value: event.value});
  }

  getSelectAllValue(event: boolean){
    this.sendSelectValue.emit({name: this.controlName, value: event ? this.option : null});
  }

  ngAfterViewInit(): void {
    this.setInitialValue();
  }

  toggleSelectAll(selectAllValue: boolean){
    this.filteredSelectList.pipe(take(1), takeUntil(this.onDestroy))
      .subscribe(val => {
        if(selectAllValue){
          this.selectListCtrl.patchValue(val);
        }else{
          this.selectListCtrl.patchValue(null);
        }
      });
  }

  setInitialValue(){
    this.filteredSelectList
      .pipe(take(1), takeUntil(this.onDestroy))
      .subscribe(() => {
        this.selectControl.compareWith = (a: any, b: any) => a && b && a[this.id] === b[this.id];
      });
  }

  filterSelectList(){
    if(!this.option){
      return;
    }
    let search = this.selectListFilterCtrl.value;
    if(!search){
      this.filteredSelectListCache = this.option.slice();
      this.filteredSelectList.next(this.filteredSelectListCache);
      return;
    }
    else{
      search = search.toLowerCase();
    }
    this.filteredSelectListCache = this.searchProperty ? this.option.filter(val => val[this.searchProperty].toLowerCase().indexOf(search) > -1) : this.option.filter((val) => JSON.stringify(val).toLowerCase().includes(search.toString().toLowerCase()));
    this.filteredSelectList.next(this.filteredSelectListCache);
  }

  protected setToggleAllCheckboxState(){
    let filteredLength = 0;
    if(this.selectListCtrl && this.selectListCtrl.value && this.isMultiple && this.showSelectAll){
      this.filteredSelectListCache.forEach(el => {
        if(this.selectListCtrl.value.indexOf(el) > -1){
          filteredLength++;
        }
      });
      this.isIndeterminate = filteredLength > 0 && filteredLength < this.filteredSelectListCache.length;
      this.isChecked = filteredLength > 0 && filteredLength === this.filteredSelectListCache.length;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}